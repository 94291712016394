import React from 'react';
import './App.css'; // import CSS file for styling
import BannerImage from './assets/comingsoon.png'
function App() {
  return (
    <div className="container">
      <div className="content">
        <img src={BannerImage} alt="background image" className="responsive-image" />
        <h1 className="responsive-text">For details contact <a href="mailto:info@physical.tv">info@physical.tv</a>
</h1>
      </div>
    </div>
  );
}

export default App;
